import React from 'react';
import { Box } from 'grommet';
import { Footer } from '@appkit4/react-components/footer';

export default function FooterApp() {
  const footerContent = "© 2023 PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.";
  const footerType = 'links';
  const footerLinks = [
      { name: 'Privacy policy', href: 'https://www.pwc.com/us/en/site/privacy.html' },
      { name: 'Terms and conditions', href: 'https://www.pwc.com/us/en/site/terms-and-conditions.html' },
      { name: 'Cookie notice', href: 'https://www.pwc.com/us/en/site-information.html' }
  ];
  // This is the function for customizing the link items of the footer
  const renderFooterItemFunc = (item: { name: string, href: string }, index: number) => {
      (<div className="ap-footer-link" key={index}>
          <a className="ap-link" href={item.href}>{item.name}</a>
          <span className="ap-footer-divider">|</span>
      </div>)
  };

  return (
    <Box
      pad="10px 50px 20px"
      width="90%"
      margin={{bottom: '20px'}}
    >
      <Footer content={footerContent} type={footerType} links={footerLinks}>Hello</Footer>
    </Box>
  );
}