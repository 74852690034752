import React from 'react';
import { Box, Heading } from 'grommet';
import LoginApp from '../components/Login';
import FooterApp from '../components/Footer'

function Home() {
  return (  
    <Box
      direction="column"
      width="100vw"
      height="92vh"
      background={{
        color: '#ffb600',
        image: 'url(./img/pe_cyber_login_page_bg@2x.png)',
        repeat: 'no-repeat',
        size: 'cover',
        position: 'bottom right'
      }}
    >
      <Box
        width="100%"
        height="100%"
        direction={'row'}
        align={'center'}
      >
        <Box
        pad="large"
        >
          <Box direction="column" justify="between">
            <Heading level={3} margin={{bottom: "10px"}} style={{color: '#474747'}}>Discover</Heading>
            <Heading level={1} margin={{bottom: "medium"}} style={{color: '#474747', fontSize: '52px', letterSpacing: '-1px', marginLeft: '-3px'}}>ProEdge <span style={{color: '#d04a02'}}>Professionals</span></Heading>
            <Heading level={4} margin={{bottom: "medium"}} style={{color: '#474747', fontWeight: 'normal', maxWidth: '560px'}}>ProEdge is the place where a company’s people (citizens) can innovate in the flow of work by learning, applying, and sharing new ways of working. We call this citizen-led innovation.
<br /><br/>
            <strong>Welcome. We're glad you are here.</strong></Heading>
          </Box>
          <LoginApp />
        </Box>
      </Box>

      <FooterApp />
    </Box>
  );
}

export default Home;
