import React, { useState } from 'react';
import { navigate } from 'gatsby'
import { Input } from '@appkit4/react-components/field';
import { Tooltip } from '@appkit4/react-components/Tooltip';
import { Button } from '@appkit4/react-components/button';

const LoginApp = () => {
  const [value, setValue] = useState("");
  const onChange = (value: any, event: any) => {
    setValue(value);
  };
  const suffix = (
    <Tooltip position='top' hideTooltipOnBlur={false}
      hasSwitch={true} trigger='hover' distance={4} content="Contact your sales leader to access this application.">
      <button data-tooltip="true" tabIndex={0} role="button" aria-label="tooltip" className="Appkit4-icon icon-information-outline ap-field-icon-btn" aria-describedby="field-tooltip"></button>
    </Tooltip>
  );
  const navToWelcome = () => {
    navigate('/welcome')
  }
  return (
    <form>
    <div className='ap-field-demo-wrapper'>
      <Input
        required={true}
        type={"text"}
        name={"email"}
        title={"Email address"}>
      </Input>
    </div>
    <br />
    <div className='ap-field-demo-wrapper'>
      <Input 
        type={"password"} 
        name={"password"}
        title={"Password"}  
        revealer={true}
        onChange={onChange} />
    </div>
    <br />
    <Button kind='primary' className="ap-bg-primary-orange-05" onClick={navToWelcome}>Submit</Button>
    </form>
  );
}

export default LoginApp;